* {
  box-sizing: border-box;
}

body {
  font-family: 'Ubuntu', sans-serif;
  font-siz: 13px;
  font-weight: 300;
  color: #333;
  background-color: #f7f7f7;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.main {
  display: flex;
  width: 100%;
  padding-top: 75px;

  &.home {
    justify-content: center;
    align-content: center;
    min-height: 100vh;
    align-items: center;
    padding-top: 0;
  }
}

.title {
  font-size: 36px;
  text-align: center;
  font-weight: 300;
}
