.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project {
  width: 250px;
  margin: 15px;
  padding: 15px;
  border: 1px #333 solid;

  .sticky & {
    width: 100%;
    padding: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 15px;

    .sticky & {
      padding: 15px 15px 5px 15px;
      border-bottom: 1px #333 solid;
    }
  }

  .project-content {
    .sticky & {
      padding: 15px;
    }
  }
}
